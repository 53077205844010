import React from "react";
import SuccessPageAnimation from "../successPageAnimation";
import wlogo  from "../../images/wlogo.svg"
import SuccessPageLeftGraphic  from "../../images/success-page-left.svg"
import SuccessPageRightGraphic  from "../../images/success-page-right.svg"

import styles from "./styles.module.css";
import { Link } from "gatsby";

const SuccessPage = ({isWealth = false, location}) => {
    const email = location && location.state && location.state.email;
    return (
        <div className={[styles.container].join(' ')}>
            <Link className={styles.mprofitLogo} to="/"><img src={wlogo} alt="MProfit Logo" /></Link>
            <img src={SuccessPageLeftGraphic} className={styles.successPageLeftGraphic}/>
            <img src={SuccessPageRightGraphic} className={styles.successPageRightGraphic}/>
            <SuccessPageAnimation/>
            <div className={styles.loginInstructions}>Login instructions sent to your email!</div>
            <div className={styles.thankYou}>Thank you for signing up for MProfit</div>
            <div className={styles.bottomMessage}>{`If you don't see the email in your inbox${email ? ` (${email})`: ''}, please check your spam folder`}</div>
        </div>
    );
}


export default SuccessPage